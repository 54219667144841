import { inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewBaseComponent } from './base-component';
import { LoaderService } from '@app/shared/ui-components/loader/service/loader.service';
import { InfoMessageType } from '@app/shared/ui-components/info-message/InfoMessageType';

@Injectable()
export abstract class ViewBaseSmartComponent extends ViewBaseComponent{
  abstract getPageName: () => string;
  protected loaderService: LoaderService = inject(LoaderService);
  protected router = inject(Router);
  protected route: ActivatedRoute = inject(ActivatedRoute);
  public infoMessageTypes = InfoMessageType;
  ngOnInit() {
    this.navigationService.initPageNameAndCallBack(this.getPageName);
 }

  // You can add other common methods here
}
