import { inject, Injectable } from '@angular/core';
import { LineStatus } from '@app/core/models/enums/line-status';
import { OrderStatus } from '@app/core/models/enums/order-status.enum';
import { LoggerService } from '@app/core/services/log/logger.service';
import { NavigationService } from '@app/core/services/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ViewBaseComponent {
  protected translateService: TranslateService = inject(TranslateService);
  protected navigationService: NavigationService = inject(NavigationService);
  protected logger: LoggerService = inject(LoggerService);


  /**
   * Translates the given status using the translation service.
   * @param status - The status to translate (OrderStatus or string).
   * @returns The translated label for the status.
   */
  labeliseOrderStatus(status: OrderStatus | string): string {
    return this.translateService.instant(`GL.ENUM.OrderStatus.${status}`);
  }

  labeliseLineStatus(status: LineStatus | string): string {
    return this.translateService.instant(`GL.ENUM.LineStatus.${status}`);
  }


  // You can add other common methods here
}
